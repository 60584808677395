import React from "react";
import storeText from "../../static/images/text-hibarin-store.png";
import styles from "./hibarin.module.scss";
import masanStoreImage from "../../static/images/store-masan.png";
import newredStoreImage from "../../static/images/store-newred.png";
import mapImage from "../../static/images/img-map-korea.png";
import markerImage from "../../static/images/img-p-store-map-marker.png";
import markerOnMapImage from "../../static/images/icon-map-marker.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "../contact/bootstrap.scss";

function SabotenStore() {
  return (
    <>
      <section className={styles.storeSection}>
        <div className={styles.titleTextWrapper}>
          <img src={storeText} alt="store" />
        </div>
        <div className={styles.storeImageWrapper}>
          <div className="position-relative">
            <div className={styles.storeTextWrapper}>
              <div className="container">
                <div className={styles.storeTextTitle}>신세계 마산점</div>
                <div className={styles.storeTextDescription}>
                  신세계마산점의 깔끔한 인테리어와<br />언제나 맛있는 정통 카츠를 즐겨보세요.
                </div>
                <div className={styles.storeTextAddress}>- 주소 : 경상남도 창원시 마산합포구 합포로 251 신세계백화점 마산점 B1</div>
              </div>
            </div>
            <div>
              <img
                src={masanStoreImage}
                alt="masan store"
                className="img-fill"
              />
            </div>
          </div>
          <div className="position-relative">
            <div className={styles.storeTextWrapper}>
              <div className="container text-right">
                <div className={styles.storeTextTitle}>
                  고양 스타필드점
                </div>
                <div className={styles.storeTextDescription}>
                  Reserve 매장인 고양 스타필드점에서<br />사보텐의 정통과 다양한 시그니처 메뉴를 만나보세요.
                </div>
                <div className={styles.storeTextAddress}>- 주소 : 경기 고양시 덕양구 고양대로 1995 1층</div>
              </div>
            </div>
            <div>
              <img
                src={newredStoreImage}
                alt="new red store"
                className="img-fill"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className={styles.storeMapWrapper}>
            <div>
              <div className={styles.storeMarkerWrapper}>
                <img src={markerImage} alt="marker" />
              </div>
              <div className={styles.storeMapTitle}>
                당신의 일상에서 사보텐을 만나보세요.
              </div>
              <div className={styles.storeMapDescription}>
                매장을 지도로
                <br />
                더욱 쉽고 빠르게 찾아보아요
                <br />
                지도로 더욱 쉽고 빠르게 찾아보아요
              </div>
              <div>
                <a
                  className={styles.kaliscoButton}
                  href={`https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90?placeSearchOption=fromNxList=true%26noredirect=1%26entry=pll&c=14135681.1787198,4517242.0563975,13,0,0,0,dh`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  사보텐 매장찾기
                </a>
              </div>
            </div>
            <div>
              <div className={styles.storeMapImageWrapper}>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      서울
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90%20%EA%B0%95%EB%82%A8?c=14145111.5970845,4510988.5520209,12,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "95px", left: "85px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      고양
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90%20%EC%8A%A4%ED%83%80%ED%95%84%EB%93%9C%EA%B3%A0%EC%96%91?c=14125842.2999852,4529648.7415510,15,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "90px", left: "50px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      인천
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90%20%EC%9D%B8%EC%B2%9C?c=14086694.5982175,4500459.3513746,11,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "140px", left: "70px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      충남
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90%20%EC%B6%A9%EB%82%A8?c=14115626.0134235,4396734.3039916,9,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "180px", left: "40px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      대전
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90%20%EB%8C%80%EC%A0%84?c=14165539.3928937,4348922.9427992,10,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "230px", left: "115px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      광주
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90%20%EA%B4%91%EC%A3%BC?c=14123365.2625373,4187583.4853463,12,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "320px", left: "100px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      창원
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%EC%82%AC%EB%B3%B4%ED%85%90%20%EC%B0%BD%EC%9B%90?c=14322502.8304773,4193335.3717248,11,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "360px", right: "70px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <img src={mapImage} alt="korea map" className="img-fluid" />
              </div>
            </div>
          </div>
        
        
        
        <div className={styles.storeInfotblWrapper}>
          <table className={styles.saboten}>
            <colgroup>
              <col width="35%" />
              <col width="" />
            </colgroup>
            <thead>
              <tr>
                <th>매장명</th>
                <th>주소</th>
              </tr>
            </thead>
            <tbody>
            <tr>
              <td>사보텐 대치점</td>
              <td>서울 강남구 대치동 989-1번지 1층</td>
            </tr>
            <tr>
              <td>사보텐 GFC점</td>
              <td>서울 강남구 역삼1동 737 강남파이낸스센터 지하 1층</td>
            </tr>
            <tr>
              <td>사보텐 선릉역점</td>
              <td>서울 강남구 역삼동 707-4 메트라이프타워 1층</td>
            </tr>
            {/*<tr>
              <td>사보텐 경방타임스퀘어점</td>
              <td>서울 영등포구 영중로 15 경방타임스퀘어 지하1층</td>
            </tr>*/}
            <tr>
              <td>사보텐 신천점</td>
              <td>서울 송파구 잠실동 176-4번지 1층</td>
            </tr>
            <tr>
              <td>사보텐 인천스퀘어원점
              </td>
              <td>인천광역시 연수구 청능대로 210 스퀘어원 3층
              </td>
            </tr>
            {/*<tr>*/}
            {/*  <td>사보텐 여의도메리츠점*/}
            {/*  </td>*/}
            {/*  <td>서울 영등포구 여의도동 25-7 여의도메리츠화재빌딩 1층*/}
            {/*  </td>*/}
            {/*</tr>*/}
            <tr>
              <td>사보텐 신논현점
              </td>
              <td>서울 서초구 서초동 1303-16 아이파크에비뉴 1층
              </td>
            </tr>
            <tr>
              <td>사보텐 압구정본점
              </td>
              <td>서울 강남구 신사동 609-3 금사빌딩 1층
              </td>
            </tr>
            <tr>
              <td>사보텐 아워홈빌딩점
              </td>
              <td>서울 강남구 역삼동 830-24번지 아워홈빌딩 1층
              </td>
            </tr>
            {/*<tr>
              <td>사보텐 사당점
              </td>
              <td>서울 서초구 방배동 444-3 파스텔시티 4층
              </td>
            </tr>*/}
            {/*<tr>*/}
            {/*  <td>사보텐 압구정역점*/}
            {/*  </td>*/}
            {/*  <td>서울 강남구 압구정로28길 22 1층(신사동)*/}
            {/*  </td>*/}
            {/*</tr>*/}
            <tr>
              <td>사보텐 코엑스점
              </td>
              <td>서울 강남구 삼성동 159 코엑스몰 지하1층 D110호
              </td>
            </tr>
            <tr>
              <td>사보텐 교대역점
              </td>
              <td>서울 서초구 서초4동 1691-2번지
              </td>
            </tr>
            <tr>
              <td>사보텐 고양스타필드점
              </td>
              <td>경기 고양시 덕양구 고양대로 1955 1층 사보텐
              </td>
            </tr>
            <tr>
              <td>사보텐 현대미아점
              </td>
              <td>서울 성북구 동소문로 315 현대백화점 9층
              </td>
            </tr>
            <tr>
              <td>사보텐 신세계의정부
              </td>
              <td>경기도 의정부시 평화로 525 신세계백화점 의정부점 9층 전문식당가 사보텐
              </td>
            </tr>
            {/*<tr>
              <td>사보텐 서여의도점
              </td>
              <td>서울 영등포구 여의도동 13-2 삼보빌딩 1층
              </td>
            </tr>*/}
            <tr>
              <td>사보텐 건대스타시티점
              </td>
              <td>서울 광진구 자양동 227-7 더샵스타시티 지하 1층
              </td>
            </tr>
            <tr>
              <td>사보텐 인천공항T2 교통센터점</td>
              <td>인천 중구 제2터미널대로 444 인천공항2터미널 지하1층</td>
            </tr>
            <tr>
              <td>사보텐 인천공항탑승동점</td>
              <td>인천광역시 중구 272</td>
            </tr>
            <tr>
              <td>사보텐 청량리역사점
              </td>
              <td>서울 동대문구 왕산로 214 청량리역사 3층 샤롯홀
              </td>
            </tr>
            <tr>
              <td>사보텐 신세계마산점
              </td>
              <td>경남 마산시 산호동 10-3 신세계백화점 지하1층 고메스트리트
              </td>
            </tr>
            <tr>
              <td>사보텐 신세계광주점
              </td>
              <td>광주 서구 광천동 49-1 신세계백화점 지하1층
              </td>
            </tr>
            {/*05-13 요청에 의해서 삭제*/}
            {/*<tr>*/}
            {/*  <td>사보텐 청주터미널점*/}
            {/*  </td>*/}
            {/*  <td>충북 청주시 흥덕구 가경동 1416-2 메가폴리스 1층*/}
            {/*  </td>*/}
            {/*</tr>*/}
            <tr>
              <td>사보텐 AK평택점
              </td>
              <td>경기도 평택시 평택로51 AK플라자 7층
              </td>
            </tr>
            <tr>
              <td>사보텐 시흥프리미엄아울렛점
              </td>
              <td>경기도 시흥시 서해안로 699 시흥프리미엄아울렛 1121호 푸드코트
              </td>
            </tr>
            <tr>
              <td>사보텐 AK분당점
              </td>
              <td>경기 성남시 분당구 황새울로360번길 42 AK분당점 7층
              </td>
            </tr>
            <tr>
              <td>사보텐 신세계대구점
              </td>
              <td>대구광역시 동구 동부로 149 8층
              </td>
            </tr>
            <tr>
              <td>사보텐 파주프리미엄아울렛점</td>
              <td>경기 파주시 탄현면 필승로 200 파주프리미엄아울렛</td>
            </tr>
            <tr>
              <td>사보텐 AK수원점</td>
              <td>경기도 수원시 팔달구 덕영대로 924, 6층 식당가</td>
            </tr>
            {/*아래 추가는 2024-05-13 메일 요청에 의해서 추가*/}
            <tr>
              <td>사보텐 스타필드위례점</td>
              <td>경기도 하남시 위례대로 200, 4층 4104호</td>
            </tr>
            {/*아래 추가는 2024-05-22 메일 요청에 의해서 추가*/}
            <tr>
              <td>사보텐 현대천호점</td>
              <td>서울 강동구 천호대로 1005 현대백화점 천호점 12층</td>
            </tr>
            {/*아래 추가는 2025-01-13 메일 요청에 의해서 추가*/}
            <tr>
              <td>사보텐 여주첼시점</td>
              <td>경기도 여주시 명품로 360 여주프리미엄아울렛</td>
            </tr>
            </tbody>
          </table>
        </div>


        </div>
      </section>
    </>
  );
}

export default SabotenStore;
