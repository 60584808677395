import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import Nav from "../../components/nav/Nav";
import MainVerticalList from "../../components/mainVerticalList/MainVerticalList";
import MobileHomePage from "../mobile/HomePage";
import {useMobileCheck} from "../../utils/mobile";
import FinancialStatements from "../../static/images/financialStatements-1.png";
import {ModalContext} from "../../components/brand/modal/modal-context";
import FinancialStatementsModal from "../../components/brand/modal/FinancialStatementsModal";
import mainImage from "../../static/images/kalisco-main-modal.jpg";
import "../Popup/kaliscoPopup.scss";
function HomePageComponent() {

    return (
        <>
            <Nav/>
            <MainVerticalList/>
        </>
    );
}
function Modal({ onClose }) {
    const handleImageClick = () => {
        window.location.href = "https://crmapp.kalisco.co.kr/account/login";
        // 새 창에서 열고 싶다면: window.open("https://crmapp.kalisco.co.kr/account/login", "_blank");
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                {/* 우측 상단 X 버튼 */}
                <button className="modal-close" onClick={onClose}>
                    X
                </button>
                {/* 클릭 시 링크로 이동하는 이미지 */}
                <img
                    className="modal-image"
                    src={mainImage}
                    alt="Financial Statements"
                    onClick={handleImageClick}
                />
            </div>
        </div>
    );
}
export default function HomePage() {
    const {setModalOpen} = useContext(ModalContext);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [isModalOpen, setIsModalOpen] = useState(true);
    useLayoutEffect(() => {
        window.addEventListener("resize", (e) => {
            setWidth(e.target.innerWidth);
            setHeight(e.target.innerHeight);
        });

        return () => {
            window.removeEventListener("resize", (e) => {
                setWidth(e.target.innerWidth);
                setHeight(e.target.innerHeight);
            });
        };
    }, []);

    // const kaliscoPopupValidate = () => {
    //     return sessionStorage.getItem("popupDisplayed") !== "true";
    // }
    //
    // const kaliscoPopup = () => {
    //     const modalData = {
    //         delayKey: 1,
    //     }
    //     sessionStorage.setItem("popupDisplayed", "true");
    //     setModalOpen(modalData);
    // }

    useEffect(() => {
        // kaliscoPopup();
    }, []);

    return (
        <>
            {useMobileCheck(width) ? (
                <HomePageComponent />
            ) : (
                <MobileHomePage rootHeight={height} />
            )}
            {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
        </>
    );
}
