import React from "react";
import storeText from "../../static/images/text-hibarin-store.png";
import styles from "./hibarin.module.scss";
import gangnamStoreImage from "../../static/images/img-p-store-gangnam.jpg";
import ssgStoreImage from "../../static/images/img-p-store-ssg.jpg";
import yeojuStoreImage from "../../static/images/img-p-store-yeoju.png";
import mapImage from "../../static/images/img-map-korea.png";
import markerImage from "../../static/images/img-p-store-map-marker.png";
import markerOnMapImage from "../../static/images/icon-map-marker.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "../contact/bootstrap.scss";

function HibarinStore() {
  return (
    <>
      <section className={styles.storeSection}>
        <div className={styles.titleTextWrapper}>
          <img src={storeText} alt="store" />
        </div>
        <div className={styles.storeImageWrapper}>
          <div className="position-relative">
            <div className={styles.storeTextWrapper}>
              <div className="container">
                <div className={styles.storeTextTitle}>신세계강남점</div>
                <div className={styles.storeTextDescription}>
                  현대적으로 재해석한 카츠와 누들, 라이스를 즐길 수 있는
                  <br />
                  히바린 신세계 강남점을 만나보세요.
                </div>
              </div>
            </div>
            <div>
              <img
                src={gangnamStoreImage}
                alt="busan store"
                className="img-fill"
              />
            </div>
          </div>
          <div className="position-relative">
            <div className={styles.storeTextWrapper}>
              <div className="container text-right">
                <div className={styles.storeTextTitle}>신세계센텀점</div>
                <div className={styles.storeTextDescription}>
                  엄선된 식재료를 바탕으로 한 프리미엄 카츠와
                  <br />
                  다채로운 메뉴를 통헤
                  <br />
                  차별화된 미식 경험을 즐겨보세요.
                </div>
              </div>
            </div>
            <div>
              <img
                src={ssgStoreImage}
                alt="songpa store"
                className="img-fill"
              />
            </div>
          </div>
          <div className="position-relative">
            <div className={styles.storeTextWrapper}>
              <div className="container">
                <div className={styles.storeTextTitle}>여주 첼시점</div>
                <div className={styles.storeTextDescription}>
                  퀄리티 있는 프리미엄 카츠와 모던한 공간으로 연인, 친구들과
                  함께
                  <br />
                  여주 첼시점만의 시그니쳐 메뉴 명란치즈카츠를 만나보세요
                </div>
              </div>
            </div>
            <div>
              <img
                src={yeojuStoreImage}
                alt="yeoju store"
                className="img-fill"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className={styles.storeMapWrapper}>
            <div>
              <div className={styles.storeMarkerWrapper}>
                <img src={markerImage} alt="marker" />
              </div>
              <div className={styles.storeMapTitle}>
                당신의 일상에서 히바린을 만나보세요.
              </div>
              <div className={styles.storeMapDescription}>
                매장을 지도로
                <br />
                더욱 쉽고 빠르게 찾아보아요
                <br />
                지도로 더욱 쉽고 빠르게 찾아보아요
              </div>
              <div>
                <a
                  className={styles.kaliscoButton}
                  href={`https://map.naver.com/v5/search/%ED%9E%88%EB%B0%94%EB%A6%B0?placeSearchOption=fromNxList=true%26noredirect=1%26entry=pll&c=14135529.7255359,4513992.1748852,13,0,0,0,dh`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  히바린 매장찾기
                </a>
              </div>
            </div>
            <div>
              <div className={styles.storeMapImageWrapper}>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      서울
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%ED%9E%88%EB%B0%94%EB%A6%B0%20%EC%84%9C%EC%9A%B8?c=14120288.6721489,4518345.6159933,10,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "95px", left: "85px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      파주
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%ED%9E%88%EB%B0%94%EB%A6%B0%20%ED%8C%8C%EC%A3%BC?c=14103720.9462680,4546398.0053740,13,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "70px", left: "50px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      인천
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%ED%9E%88%EB%B0%94%EB%A6%B0%20%EC%9D%B8%EC%B2%9C?c=14089866.7348914,4502828.8992514,10,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "120px", left: "50px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      경기
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%ED%9E%88%EB%B0%94%EB%A6%B0%20%EA%B2%BD%EA%B8%B0?c=14217287.2610428,4483872.5162367,9,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "140px", left: "120px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      className={styles.test}
                      placement="top"
                      delay={{ show: 250, hide: 250 }}
                      id="tooltip"
                    >
                      부산
                    </Tooltip>
                  }
                >
                  <a
                    href="https://map.naver.com/v5/search/%ED%9E%88%EB%B0%94%EB%A6%B0%20%EB%B6%80%EC%82%B0?c=14353650.9195035,4188328.7463721,10,0,0,0,dh"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={styles.storeMapMarkerPosition}
                    style={{ top: "350px", right: "30px" }}
                  >
                    <img src={markerOnMapImage} alt="marker" />
                  </a>
                </OverlayTrigger>
                <img src={mapImage} alt="korea map" className="img-fluid" />
              </div>
            </div>
          </div>
          
          
          <div className={styles.storeInfotblWrapper}>
          <table className={styles.hibarin}>
            <colgroup>
              <col width="35%" />
              <col width="" />
            </colgroup>
            <thead>
              <tr>
                <th>매장명</th>
                <th>주소</th>
              </tr>
            </thead>
              <tbody>
              {/*<tr>
                  <td>히바린 롯데인천점</td>
                  <td>인천 남구 관교동 15 롯데인천터미널점 6층</td>
              </tr>*/}
              <tr>
                  <td>히바린 신세계강남점
                  </td>
                  <td>서울시 서초구 신반포로 176 신세계 백화점 강남점 신관 11층
                  </td>
              </tr>
              <tr>
                  <td>히바린 시흥프리미엄아울렛점
                  </td>
                  <td>경기 시흥시 서해안로 699 신세계사이먼 시흥 프리미엄아울렛 1층
                  </td>
              </tr>
              {/*<tr>
                  <td>히바린 신세계경기점
                  </td>
                  <td>경기도 용인시 수지구 포은대로 536 신세계백화점경기점 7층
                  </td>
              </tr>*/}
              <tr>
                  <td>히바린 신세계센텀점
                  </td>
                  <td>부산광역시 해운대구 센텀남대로 35 신세계백화점 센텀시티점 9F
                  </td>
              </tr>
              {/*<tr>
                  <td>히바린 여주첼시점
                  </td>
                  <td>경기 여주시 상거동 460 여주 프리미엄 아울렛 East동
                  </td>
              </tr>*/}
              {/*<tr>*/}
              {/*    <td>히바린 롯데부산본점*/}
              {/*    </td>*/}
              {/*    <td>부산광역시 진구 가야대로 772 롯데백화점 부산본점 지하1층*/}
              {/*    </td>*/}
              {/*</tr>*/}
              {/*<tr>
                  <td>히바린 현대백화점중동점
                  </td>
                  <td>경기 부천시 길주로 180 현대백화점 8층
                  </td>
              </tr>*/}
              {/*아래 추가는 2025-01-13일 메일 요청에 의해 추가됨*/}
              <tr>
                  <td>히바린 롯데인천점</td>
                  <td>인천 남구 관교동 15 롯데인천터미널점 6층</td>
              </tr>
              <tr>
                  <td>히바린 신세계사우스시티점
                  </td>
                  <td>경기도 용인시 수지구 포은대로 536 신세계백화점경기점 7층</td>
              </tr>
              {/*아래 추가는 2024-05-13일 메일 요청에 의해 추가됨*/}
              <tr>
                  <td>히바린 여의도IFC몰점</td>
                  <td>서울특별시 영등포구 국제금융로 10 IFC몰 지하 3층</td>
              </tr>
              <tr>
                  <td>히바린 광화문D타워점</td>
                  <td>서울 종로구 종로3길 17 (청진동) 3층 308호</td>
              </tr>
              {/*아래 추가는 2025-01-13일 메일 요청에 의해 추가됨*/}
              <tr>
                  <td>히바린 타임스퀘어점</td>
                  <td>서울 영등포구 영중로 15 경방타임스퀘어 지하1층</td>
              </tr>
              </tbody>
          </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default HibarinStore;
